<template>
  <!-- 小程序 - 运营 - APP报名列表 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">平台报名列表(小程序)</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">APP报名列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="学员姓名" class="searchboxItem">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="form.realName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入学员姓名"
                clearable
              />
            </div>

            <div title="学员手机号" class="searchboxItem">
              <span class="itemLabel">学员手机号:</span>
              <el-input
                  v-model="form.phone"
                  type="text"
                  size="small"
                  style="width: auto"
                  placeholder="请输入学员手机号"
                  clearable
              />
            </div>

            <div title="报名课程" class="searchboxItem">
              <span class="itemLabel">报名课程:</span>
              <el-input
                  v-model="form.courseName"
                  type="text"
                  size="small"
                  style="width: auto"
                  placeholder="请输入报名课程"
                  clearable
              />
            </div>
            <div>
              <el-button class="bgc-bv" @click="getData()">查询</el-button>
              <el-button class="bgc-bv" @click="exportList">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="学员姓名"
                align="center"
                prop="realName"
                show-overflow-tooltip
              />
              <el-table-column
                label="性别"
                align="center"
                prop="sex"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.sex == 1 ? "男" : "女"
                }}</template>
              </el-table-column>
              <el-table-column label="生日" align="center" prop="birthday" />
              <el-table-column label="手机号" align="center" prop="phone" />
              <el-table-column
                  label="学校"
                  align="center"
                  prop="school"
                  show-overflow-tooltip
                  min-width="150"
              />
              <el-table-column label="学历" align="center" prop="education" >
                <template slot-scope="scope">
                  {{ $setDictionary("EDUCATION", scope.row.education) }}
                </template>
              </el-table-column>
              <el-table-column
                label="专业"
                align="center"
                prop="profession"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="报名课程"
                align="center"
                prop="courseName"
                min-width="200"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { console } from "../../../utils/helper";
export default {
  name: "distributorList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      form: {
        realName: "",
        courseName: "",
        phone: ""
      },
    };
  },
  computed: {},
  // 过滤器
  filters: {
    getState(val) {
      let dic = {
        10: "待审核",
        20: "已通过",
        30: "已驳回",
      };
      return dic[val];
    },
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.form.realName) {
        params.realName = this.form.realName;
      }
      if (this.form.phone) {
        params.phone = this.form.phone;
      }
      if (this.form.courseName) {
        params.courseName = this.form.courseName;
      }
      this.doFetch(
        {
          url: "/apply/edu-course-apply/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 导出
    exportList() {
      this.$post(
          '/apply/edu-course-apply/export',
          {
            realName: this.form.realName,
            phone: this.form.phone,
            courseName: this.form.courseName
          },
          3000,
          true,
          2
      ).then((res) => {
        if (res.status == "0") {
          window.open(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less"></style>
